import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import {
    CollaborationProvider,
    ErrorLoadingFullPage,
    ErrorLoadingState,
    ResponsiveDrawerLayout,
    Shell,
    useCollaborationContext,
    useGetCollaborationQuery,
    useRevisionRequestPanelData,
} from '@99designs/collaborate-views';
import { SwanModeProvider } from '@99designs/design-services-layouts';
import { __ } from '@99designs/i18n';
import { RevisionCanvas } from '../canvas/RevisionCanvas';
import { RevisionPanel } from '../revision/Revision';

export type RevisionRouteParams = {
    collaborationId: string;
    deliveryVersionId: string;
};

export function Feedback() {
    const { collaborationId } = useParams<RevisionRouteParams>() as RevisionRouteParams;
    const { data, error, loading } = useGetCollaborationQuery({
        variables: {
            collaborationPublicId: collaborationId,
        },
    });

    return (
        <ErrorLoadingFullPage loading={loading} error={error} data={data}>
            {(data) => (
                <CollaborationProvider
                    collaboration={data.getCollaboration.collaboration}
                    collaborationId={collaborationId}
                >
                    <SwanModeProvider value={{ isCompactMode: true }}>
                        <Shell>
                            <FeedbackContent />
                        </Shell>
                    </SwanModeProvider>
                </CollaborationProvider>
            )}
        </ErrorLoadingFullPage>
    );
}

const FeedbackContent = () => {
    const { collaborationId } = useCollaborationContext();
    const { deliveryVersionId } = useParams<RevisionRouteParams>() as RevisionRouteParams;
    const {
        data: revisionRequest,
        loading,
        error,
    } = useRevisionRequestPanelData({
        collaborationPublicId: collaborationId,
        deliveryVersionId: Number(deliveryVersionId),
    });

    return (
        <ErrorLoadingState data={revisionRequest} loading={loading} errors={error}>
            {(revisionRequest) => {
                return (
                    <>
                        <Helmet>
                            <title>{__('Vistaprint | Design Services Revision Feedback')}</title>
                        </Helmet>
                        <ResponsiveDrawerLayout
                            left={
                                <RevisionPanel
                                    revisionRequest={revisionRequest}
                                    handleUpdate={() => ({})}
                                    deliveryVersionId={deliveryVersionId}
                                    collaborationId={collaborationId}
                                    feedbackView={true}
                                />
                            }
                            right={
                                <RevisionCanvas
                                    subRoute="revision"
                                    revisionRequestId={revisionRequest.id}
                                    deliveryId={deliveryVersionId}
                                    interactable={false}
                                    revisionRequest={revisionRequest}
                                    handleUpdate={() => ({})}
                                    hideDrawer
                                />
                            }
                            hidePageOverlay={true}
                        />
                    </>
                );
            }}
        </ErrorLoadingState>
    );
};
