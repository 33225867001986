import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
    SWAN_BASE_URL_MAP,
    SWAN_STYLE_KEY_MAP,
    SwanHead,
    SwanProvider,
    getRootClassNames,
    getSwanJsUrl,
    useBrowserClasses,
} from '@vp/swan';

const eagerlyLoadedStyles = [
    SWAN_STYLE_KEY_MAP.thumbnailsHero,
    SWAN_STYLE_KEY_MAP.button,
    SWAN_STYLE_KEY_MAP.accordion,
    SWAN_STYLE_KEY_MAP.tabs,
    SWAN_STYLE_KEY_MAP.core,
    SWAN_STYLE_KEY_MAP.grid,
    SWAN_STYLE_KEY_MAP.alertBox,
    SWAN_STYLE_KEY_MAP.icon,
    SWAN_STYLE_KEY_MAP.utility,
    SWAN_STYLE_KEY_MAP.carousel,
    SWAN_STYLE_KEY_MAP.modalDialog,
    SWAN_STYLE_KEY_MAP.hidden,
    SWAN_STYLE_KEY_MAP.toggleSwitch,
    SWAN_STYLE_KEY_MAP.visible,
    SWAN_STYLE_KEY_MAP.standardTile,
    SWAN_STYLE_KEY_MAP.progressiveImage,
    SWAN_STYLE_KEY_MAP.hidden,
    SWAN_STYLE_KEY_MAP.visible,
    SWAN_STYLE_KEY_MAP.popover,
    SWAN_STYLE_KEY_MAP.spinner,
    SWAN_STYLE_KEY_MAP.listbox,
    SWAN_STYLE_KEY_MAP.zoomControls,
    SWAN_STYLE_KEY_MAP.selectionSet,
    SWAN_STYLE_KEY_MAP.avatar,
];

interface SwanConfigurationProps {
    children: React.ReactNode;
}

export const SwanConfiguration = ({ children }: SwanConfigurationProps) => {
    const browser = useBrowserClasses();
    const rootClassName = getRootClassNames();

    useEffect(() => {
        const src = getSwanJsUrl('popover', undefined, SWAN_BASE_URL_MAP.default.cdn);
        const s = document.createElement('script');
        s.setAttribute('src', src ?? '');
        document.body.appendChild(s);
    }, []);

    return (
        <SwanProvider>
            <HelmetProvider>
                <Helmet
                    htmlAttributes={{ class: browser }}
                    bodyAttributes={{ class: rootClassName }}
                />
                <SwanHead
                    renderWith={Helmet}
                    renderStyleContentAsChildren
                    styleSheetKeys={eagerlyLoadedStyles}
                    swanBaseUrl={SWAN_BASE_URL_MAP.default.cdn}
                />
                {children}
            </HelmetProvider>
        </SwanProvider>
    );
};
