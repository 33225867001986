import {
    BriefProvider,
    FieldGroupVariant,
    fieldGroups,
} from '@99designs/feature-design-services-brief';
import { BriefFragment, useCollaborationContext } from '../../context';
import { NotFound } from '../../ui';

export type BriefLayoutData = {
    groups: ReturnType<typeof fieldGroups>;
    brief: BriefFragment;
    collaborationId: string;
};

export const BriefLayout = ({
    children,
}: {
    children: (data: BriefLayoutData) => JSX.Element | undefined;
}) => {
    const { collaborationId, brief } = useCollaborationContext();

    if (!brief) {
        return <NotFound />;
    }

    const groups = fieldGroups(brief, FieldGroupVariant.READONLY);

    return (
        <BriefProvider>
            {children({
                groups,
                brief,
                collaborationId,
            })}
        </BriefProvider>
    );
};
