import { useTracking } from '@vp/react-tracking';
import Bugsnag from '@bugsnag/js';
import { __ } from '@99designs/i18n';
import { ICollaborationContext, useAlertContext, useCollaborationContext } from '../context';
import { getNextPageUrl } from './getNextPageUrl';
import { useWorkEntity } from './useWorkEntity';

const errorMsg = __('An unexpected error occurred. Please try again later.');

const goToPostConfig = async (
    {
        workId,
        mpvId,
        locale,
        productOptions,
    }: {
        workId: string;
        mpvId: string;
        locale: string;
        productOptions?: { [productOption: string]: string };
    },
    setPageError: (pageError: string) => void
): Promise<void> => {
    try {
        if (workId && mpvId) {
            const url = await getNextPageUrl(locale, mpvId, productOptions ?? undefined);
            const nextUrl = url.replace('{workId}', workId);
            const domain = domainLookup[locale.toLowerCase()] ?? '';

            window.location.href = domain + nextUrl;
        } else {
            throw new Error(`Error getting workId or mpvId from currentDeliverable`);
        }
    } catch (e) {
        Bugsnag.notify(new Error('Error navigating to post config'));
        setPageError(errorMsg);
    }
};

export function parseMPVURL(MPVURL: string): { mpvId?: string; locale?: string } {
    const url = new URL(MPVURL);
    const pathParts = url.pathname.split('/').filter((part) => part);

    // Verify that the URL matches the expected format: https://merchandising-product-service.cdn.vpsvc.com/api/v3/mpv/vistaprint/{locale}/{mpvid}
    if (
        url.hostname !== 'merchandising-product-service.cdn.vpsvc.com' ||
        pathParts.length < 6 ||
        pathParts[0] !== 'api' ||
        pathParts[1] !== 'v3' ||
        pathParts[2] !== 'mpv' ||
        pathParts[3] !== 'vistaprint'
    ) {
        return {};
    }

    return {
        locale: pathParts[4],
        mpvId: pathParts[5],
    };
}

function getMpvId(
    collab: Pick<ICollaborationContext, 'printProduct' | 'locale' | 'collaborationId'>,
    workEntity: ReturnType<typeof useWorkEntity>['workEntity']
): { mpvId: string; locale: string } {
    const collabDefaults = { mpvId: collab.printProduct.mpvId, locale: collab.locale };
    if (workEntity == null) {
        Bugsnag.notify(
            new Error(`work entity is missing for collaboration id ${collab.collaborationId}`)
        );
        return collabDefaults;
    }
    if (workEntity?.merchandising == null) {
        Bugsnag.notify(
            new Error(`merchandising is missing for collaboration id ${collab.collaborationId}`)
        );
        return collabDefaults;
    }

    const { mpvId, locale } = parseMPVURL(workEntity.merchandising.MPVURL);

    if (!mpvId || !locale) {
        Bugsnag.notify(
            new Error(
                `mpv id and locale could not be derived from mpvurl ${workEntity.merchandising.MPVURL} collaboration id ${collab.collaborationId}`
            )
        );
        return collabDefaults;
    }

    return { mpvId, locale };
}

export function useOrderAction() {
    const collab = useCollaborationContext();
    const { showError } = useAlertContext();
    const { workId, workEntity } = useWorkEntity();
    const { tracking } = useTracking();

    const onClickOrder = async () => {
        const { mpvId, locale } = getMpvId(collab, workEntity);

        const productOptions = collab.printProduct.productOptionsJson
            ? JSON.parse(collab.printProduct.productOptionsJson)
            : undefined;

        if (!mpvId) {
            showError(errorMsg);
            Bugsnag.notify(
                new Error(`MPV id has not been set for collaboration ${collab.collaborationId}`)
            );
            return;
        }
        if (!workId) {
            showError(errorMsg);
            Bugsnag.notify(
                new Error(
                    `Work id is missing in order action for collaboration ${collab.collaborationId}`
                )
            );
            return;
        }
        try {
            tracking.track('Collaboration Action Taken', {
                type: 'Approve & order',
                ...collab.trackingProperties,
            });
            await goToPostConfig({ workId, mpvId, locale, productOptions }, showError);
        } catch (e) {
            Bugsnag.notify(
                new Error(`Error in order action for collaboration ${collab.collaborationId}`)
            );
            showError(errorMsg);
        }
    };

    return { onClickOrder };
}

const domainLookup: { [key: string]: string } = {
    'da-dk': 'https://www.vistaprint.dk',
    'de-at': 'https://www.vistaprint.at',
    'de-ch': 'https://www.vistaprint.ch',
    'de-de': 'https://www.vistaprint.de',
    'en-au': 'https://www.vistaprint.com.au',
    'en-ca': 'https://www.vistaprint.ca',
    'en-gb': 'https://www.vistaprint.co.uk',
    'en-ie': 'https://www.vistaprint.ie',
    'en-in': 'https://www.vistaprint.in',
    'en-nz': 'https://www.vistaprint.co.nz',
    'en-sg': 'https://www.vistaprint.sg',
    'en-us': 'https://www.vistaprint.com',
    'es-es': 'https://www.vistaprint.es',
    'es-us': 'https://www.vistaprint.com',
    'fi-fi': 'https://www.vistaprint.fi',
    'fr-be': 'https://www.vistaprint.be',
    'fr-ca': 'https://www.vistaprint.ca',
    'fr-ch': 'https://www.vistaprint.ch',
    'fr-fr': 'https://www.vistaprint.fr',
    'it-ch': 'https://www.vistaprint.ch',
    'it-it': 'https://www.vistaprint.it',
    'ja-jp': 'https://www.vistaprint.jp',
    'nb-no': 'https://www.vistaprint.no',
    'nl-be': 'https://www.vistaprint.be',
    'nl-nl': 'https://www.vistaprint.nl',
    'pt-pt': 'https://www.vistaprint.pt',
    'sv-se': 'https://www.vistaprint.se',
};
