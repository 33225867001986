import { useTracking } from '@vp/react-tracking';
import { Link } from 'react-router-dom';
import { Typography, tokensRaw } from '@vp/swan';
import { FormattedReactMessage, __vistaRoute } from '@99designs/design-services-common';
import { __ } from '@99designs/i18n';
import { CollaborationEvent } from './CollaborationEvent';

export interface StartProjectEventProps {
    isDesignLive: boolean;
}

export const StartProjectEvent: React.FC<StartProjectEventProps> = ({ isDesignLive }) => {
    const { tracking } = useTracking();
    const sourceUrl = window.location.pathname;

    return (
        <CollaborationEvent showAvatar={true} color={tokensRaw.SwanSemColorBgInfo}>
            {isDesignLive ? (
                <Typography marginBottom={'3'}>
                    {__(
                        'Hi! Our design team have received your brief and are excited to work with you on the design in real-time in your 30-minute Zoom call.'
                    )}
                </Typography>
            ) : (
                <>
                    <Typography marginBottom={'3'}>
                        {__(
                            'Hi! Our design team have received your brief and are working on creating your design. There’s nothing you need to do right now!'
                        )}
                    </Typography>
                    <Typography marginBottom={'3'}>
                        {__(
                            'You will be notified by email when your design is ready for review, here in your design project home.'
                        )}
                    </Typography>
                    <Typography marginBottom={'3'}>
                        {__(
                            'If the design team have any questions about your brief, you will receive an email or call.'
                        )}
                    </Typography>
                </>
            )}
            <Typography>
                <FormattedReactMessage
                    message={__(
                        'If you have any questions or would like to contact us, please visit our <Link>help centre</Link>.'
                    )}
                    replacements={{
                        Link: (
                            <Link
                                to={__vistaRoute('/customer-care/help-center/')}
                                onClick={() => {
                                    tracking.track('Navigation Clicked', {
                                        category: 'Collaboration Panel',
                                        label: 'Content:Collaboration Panel',
                                        eventDetail: `${sourceUrl};/customer-care/help-centre;Help centre CTA;/customer-care/help-centre`,
                                        navigationDetail: 'help centre',
                                    });
                                }}
                                target="_blank"
                            />
                        ),
                    }}
                />
            </Typography>
        </CollaborationEvent>
    );
};
