import { createContext, useContext, useEffect } from 'react';
import Bugsnag from '@bugsnag/js';
import {
    CollaboratePrintProduct,
    CollaborationResponse,
    DeliveryState,
    DeliveryVersion,
    ViewerRole,
} from '@99designs/graph-utils/types';
import { __ } from '@99designs/i18n';
import { useLatestVersion } from '../../hooks';
import { BriefFragment } from './CollaborationContext.generated';

export type ICollaborationContext = {
    collaborationId: string;
    deliveries: DeliveryVersion[];
    locale: string;
    latestVersion: number;
    mpvId: string;
    revisionRequests: {
        remaining: number;
        max: number;
        used: number;
        isUnlimited: boolean;
    };
    state: DeliveryState;
    dropdownOptions: string[];
    trackingProperties: {
        [key: string]: string | string[];
    };
    brief?: BriefFragment | null;
    permissions: CollaborationContextPermissions;
    viewerRole: ViewerRole;
    hasDesignLiveDelivery: boolean;
    environmentForScheduler: string;
    printProduct: CollaboratePrintProduct;
    isFreelance: boolean;
};

export type CollaborationContextPermissions = {
    canApproveDesign: boolean;
    canEditDesign: boolean;
    canRequestRevision: boolean;
};

export const TRACKING_PAGE_MY_ACCOUNT = 'My Account';
const SCHEDULER_PRODUCTION_ENVIRONMENT = 'production';
const SCHEDULER_NON_PRODUCTION_ENVIRONMENT = 'non-production';

export function castState(state: DeliveryState): string {
    switch (state) {
        case 'DELIVERY_STATE_REQUEST_SUBMITTED':
            return __('Request submitted');
        case 'DELIVERY_STATE_DESIGN_IN_PROGRESS':
            return __('Design in progress');
        case 'DELIVERY_STATE_REVISION_IN_PROGRESS':
            return __('Edits received');
        case 'DELIVERY_STATE_READY_FOR_REVIEW':
            return __('Ready for review');
        case 'DELIVERY_STATE_CANCELLED':
            return __('Canceled');
        case 'DELIVERY_STATE_APPOINTMENT_SCHEDULED':
            return __('Appointment scheduled');
        case 'DELIVERY_STATE_APPOINTMENT_IN_SESSION':
            return __('Appointment in session');
        case 'DELIVERY_STATE_APPOINTMENT_PREPARING':
            return __('Preparing for appointment');
        default:
            return __('Request submitted');
    }
}

export const CollaborationContext = createContext<ICollaborationContext | null>(null);

/**
 * Collaboration provider contains all the global state for a given collaboration
 */
export const CollaborationProvider = ({
    collaborationId,
    collaboration,
    children,
    brief,
}: {
    collaborationId: string;
    collaboration: CollaborationResponse;
    brief?: BriefFragment | null;
    children?: React.ReactNode;
}) => {
    const { deliveryVersions, state, printProduct, permissions, viewerRole, revisions } =
        collaboration;
    const latestVersion = useLatestVersion(deliveryVersions);
    const canApproveDesign = viewerRole === 'VIEWER_ROLE_CLIENT' && deliveryVersions.length > 0;
    const canEditDesign = canApproveDesign;
    const mpvId = printProduct.mpvId;

    const context: ICollaborationContext = {
        collaborationId,
        deliveries: deliveryVersions,
        latestVersion,
        mpvId,
        revisionRequests: {
            remaining: revisions.remaining,
            max: revisions.maximum,
            used: revisions.maximum - revisions.remaining,
            isUnlimited: revisions.isUnlimited,
        },
        state,
        locale: collaboration.culture,
        dropdownOptions: ['Business Cards', 'More Business Cards'],
        trackingProperties: {
            mpvIds: [mpvId],
            collaborationStatus: castState(state),
        },
        brief,
        permissions: {
            canApproveDesign: canApproveDesign,
            canEditDesign: canEditDesign,
            canRequestRevision: permissions.canRequestRevision,
        },
        viewerRole,
        hasDesignLiveDelivery: collaboration.hasDesignLiveDelivery,
        environmentForScheduler: environmentForScheduler(collaboration),
        printProduct: collaboration.printProduct,
        isFreelance: collaboration.isFreelance,
    };

    useEffect(() => {
        //kiq is used for passing identity of user to qualaroo survery responses
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (window && (window as any)._kiq) {
            try {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-extra-semi
                (window as any)._kiq.push([
                    'set',
                    {
                        role: viewerRole === 'VIEWER_ROLE_CLIENT' ? 'client' : 'designer',
                    },
                ]);
            } catch (e) {
                Bugsnag.notify(new Error('Error in Qualaroo response'));
            }
        }
    }, [viewerRole]);

    return (
        <CollaborationContext.Provider value={context}>{children}</CollaborationContext.Provider>
    );
};

// Scheduler has different environments for production and staging
// We need to use the non-production environment for development and when is_test = true
// because when a test collaboration is launched, the appointment was made via the staging scheduler instance
const environmentForScheduler = (collab: CollaborationResponse) => {
    if (process.env.NODE_ENV === 'development' || collab.isTest) {
        return SCHEDULER_NON_PRODUCTION_ENVIRONMENT;
    }
    return SCHEDULER_PRODUCTION_ENVIRONMENT;
};

export const useCollaborationContext = () => {
    const context = useContext(CollaborationContext);
    if (!context) {
        throw new Error('useCollaborationContext must be used within a CollaborationProvider');
    }
    return context;
};
