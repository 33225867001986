import Bugsnag from '@bugsnag/js';
import { getVariantUrl } from '@99designs/design-services-common';
import { CollaborationFile } from '@99designs/graph-utils/types';

/**
 * The Cimpress api used to retrieve the file sets the disposition
 * to inline which causes it to ignore the download attribute on the anchor tag.
 * This function uses the blob api as a workaround to download the file.
 */
export const getFileDownload = async (file: CollaborationFile) => {
    try {
        const name = file.name;
        const url = getVariantUrl(file.externalUploadId, 'original');
        const response = await fetch(url);
        const blob = await response.blob();
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = name;
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(link);
    } catch (error) {
        Bugsnag.notify(new Error(`Download failed: ${error}`));
    }
};
