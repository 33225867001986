import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import {
    AppointmentPanel,
    ErrorLoadingState,
    useCollaborationContext,
} from '@99designs/collaborate-views';
import { __ } from '@99designs/i18n';
import { useAppointmentQuery } from './Appointment.generated';

export const Appointment = () => {
    const { collaborationId } = useCollaborationContext();
    const { deliveryVersionId } = useParams<{
        deliveryVersionId: string;
    }>();
    const { data, error, loading } = useAppointmentQuery({
        variables: {
            collaborationPublicId: collaborationId,
            deliveryVersionId: deliveryVersionId ? parseInt(deliveryVersionId) : 0,
        },
        skip: !collaborationId || !deliveryVersionId,
    });

    if (!deliveryVersionId) {
        throw new Error('cannot find appointment');
    }

    return (
        <>
            <Helmet>
                <title>{__('VistaPrint | Design Services Reschedule DesignLive session')}</title>
            </Helmet>
            <ErrorLoadingState
                loading={loading}
                errors={error}
                data={data?.collaborationAppointment}
            >
                {(appointment) => (
                    <AppointmentPanel
                        appointment={appointment}
                        deliveryVersionId={deliveryVersionId}
                    />
                )}
            </ErrorLoadingState>
        </>
    );
};
