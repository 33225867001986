import React from 'react';
import { Button, FlexBox, Icon, Section } from '@vp/swan';
import { __ } from '@99designs/i18n';

export interface DrawerHeaderProps {
    children?: React.ReactNode;
    onClickBack?: () => void;
}

export const DrawerHeader: React.FC<DrawerHeaderProps> = ({ children, onClickBack }) => {
    return (
        <Section paddingX="5" paddingTop="3" paddingBottom="2">
            <FlexBox justifyContent="flex-start" alignItems="center" gap="4">
                {onClickBack && (
                    <Button skin="tertiary" aria-label={__('Back')} onClick={onClickBack}>
                        <Icon iconType="arrowLeft" aria-hidden="true" size="standard" />
                    </Button>
                )}
                {children}
            </FlexBox>
        </Section>
    );
};
