import React from 'react';
import { useLocation } from 'react-router-dom';
import {
    Button,
    Icon,
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Typography,
} from '@vp/swan';
import { __ } from '@99designs/i18n';

export type VersionDropdownProps = {
    version?: number;
    setVersion: (version: number) => void;
    revisionsTotal: number;
    revisionsRemaining: number;
    hasUnlimitedRevisions: boolean;
    interactable?: boolean;
};

const Label: React.FC<
    Pick<VersionDropdownProps, 'version' | 'revisionsRemaining' | 'hasUnlimitedRevisions'>
> = ({ version, revisionsRemaining, hasUnlimitedRevisions }) => (
    <div>
        <Typography fontSkin="body-standard-bold">
            {__('Version {{version}}', { version })}
        </Typography>
        {!hasUnlimitedRevisions && (
            <Typography fontSkin="body-small">
                {revisionsRemaining === 1
                    ? __('1 revision left')
                    : __('{{x}} revisions left', { x: revisionsRemaining })}
            </Typography>
        )}
    </div>
);

const FeedbackLabel: React.FC<
    Pick<VersionDropdownProps, 'revisionsRemaining' | 'hasUnlimitedRevisions'>
> = ({ revisionsRemaining, hasUnlimitedRevisions }) => (
    <div>
        <Typography fontSkin="body-standard-bold">{__('Initial designs')}</Typography>
        {!hasUnlimitedRevisions && (
            <Typography fontSkin="body-small">
                {revisionsRemaining === 1
                    ? __('1 revision left')
                    : __('{{x}} revisions left', { x: revisionsRemaining })}
            </Typography>
        )}
    </div>
);

export const VersionDropdown: React.FC<VersionDropdownProps> = ({
    version: currentVersion,
    setVersion,
    revisionsTotal,
    revisionsRemaining,
    interactable,
    hasUnlimitedRevisions,
}) => {
    const location = useLocation();
    const isFeedbackRoute = location.pathname.includes('/feedback');

    if (!interactable) {
        return (
            <Button style={{ pointerEvents: 'none' }} textAlign="left">
                <Label
                    version={currentVersion}
                    revisionsRemaining={revisionsRemaining}
                    hasUnlimitedRevisions={hasUnlimitedRevisions}
                />
            </Button>
        );
    } else if (isFeedbackRoute) {
        return (
            <Button style={{ pointerEvents: 'none' }} textAlign="left">
                <FeedbackLabel
                    revisionsRemaining={revisionsRemaining}
                    hasUnlimitedRevisions={hasUnlimitedRevisions}
                />
            </Button>
        );
    }

    const versions = Array.from({ length: revisionsTotal }, (_, index) => 1 + index);

    return (
        <Menu
            aria-label={__('Select a version')}
            onChange={(num: string) => {
                const v = parseInt(num);
                if (isNaN(v)) {
                    return;
                }
                setVersion(v);
            }}
        >
            <MenuTrigger>
                <Button textAlign="left">
                    <Label
                        version={currentVersion}
                        revisionsRemaining={revisionsRemaining}
                        hasUnlimitedRevisions={hasUnlimitedRevisions}
                    />
                    <Icon iconType="caretDown" />
                </Button>
            </MenuTrigger>
            <MenuPopover>
                <MenuList>
                    {versions.map((version) => (
                        <MenuItem key={version.toString()} textValue={version.toString()}>
                            <Typography
                                fontSkin={
                                    version === currentVersion ? 'body-small-bold' : 'body-small'
                                }
                            >
                                {__('Version {{version}}', { version })}
                            </Typography>
                        </MenuItem>
                    ))}
                </MenuList>
            </MenuPopover>
        </Menu>
    );
};
