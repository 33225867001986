import { Box, H2 } from '@vp/swan';
import { __ } from '@99designs/i18n';
import { RemainingRevisions } from './RemainingRevisions';

export interface DesignRevisionMetaProps {
    revisionVersion: string;
    remainingRevisions: number;
    hasUnlimitedRevisions: boolean;
    feedbackView: boolean;
}

export function DesignRevisionMeta(props: DesignRevisionMetaProps) {
    return (
        <Box>
            {props.feedbackView ? (
                <H2 fontSkin="title-section">
                    {__('Comments on Initial Designs', {
                        revisionVersion: props.revisionVersion,
                    })}
                </H2>
            ) : (
                <H2 fontSkin="title-section">
                    {__('Comments on Version {{revisionVersion}}', {
                        revisionVersion: props.revisionVersion,
                    })}
                </H2>
            )}
            <RemainingRevisions
                remainingRevisions={props.remainingRevisions}
                hasUnlimitedRevisions={props.hasUnlimitedRevisions}
            />
        </Box>
    );
}
export default DesignRevisionMeta;
