/* eslint-disable */

import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';
import * as Types from '@99designs/graph-utils/types';
import {
    BriefProductFragment,
    StructuredBriefFragment,
} from '../../../../../feature-design-services-brief/src/components/BriefForm/brief.generated';

export type GetCollaborationQueryVariables = Types.Exact<{
    collaborationPublicId: Types.Scalars['String'];
}>;

export type GetCollaborationQuery = {
    __typename: 'Query';
    getCollaboration: {
        __typename: 'GetCollaborationResponse';
        collaboration: {
            __typename: 'CollaborationResponse';
            state: Types.DeliveryState;
            mpvId: string;
            culture: string;
            viewerRole: Types.ViewerRole;
            hasDesignLiveDelivery: boolean;
            isTest: boolean;
            isFreelance: boolean;
            deliveryVersions: Array<{ __typename: 'DeliveryVersion'; versionId: number }>;
            permissions: { __typename: 'CollaborationPermissions'; canRequestRevision: boolean };
            printProduct: {
                __typename: 'CollaboratePrintProduct';
                mpvId: string;
                productOptionsJson: Types.Maybe<string>;
            };
            revisions: {
                __typename: 'Revisions';
                isUnlimited: boolean;
                maximum: number;
                remaining: number;
            };
        };
        brief: Types.Maybe<{ __typename: 'StructuredBrief' } & BriefFragment>;
    };
};

export type BriefFragment = {
    __typename: 'StructuredBrief';
    product: Types.Maybe<{ __typename: 'ProductFixedPriceProject' } & BriefProductFragment>;
} & StructuredBriefFragment;

export const BriefFragmentDoc: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'Brief' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StructuredBrief' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StructuredBrief' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'BriefProduct' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'StructuredBrief' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StructuredBrief' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groups' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dynamicFields' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Field' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fulfilment' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mpvId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'productKey' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'workName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fulfilmentStrategy' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'Field' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DynamicField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InputField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MultiInputField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TextAreaField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SelectChoiceField' } },
                    {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MultiSelectChoiceField' },
                    },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GridChoiceField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RadioChoiceField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BudgetField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MultiChoiceField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DateField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FilesField' } },
                    {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SquarespacePackageChoiceField' },
                    },
                    {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'LogoContestTierChoiceField' },
                    },
                    {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ContestUpgradesChoiceField' },
                    },
                    {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PrintServiceOptionsField' },
                    },
                    {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MultiGridChoiceField' },
                    },
                    {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ClientConfiguredField' },
                    },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'WorkEntityField' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'InputField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InputField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'InputField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'helperText' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'inputValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'immutable' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'maxLength' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'descriptiveText' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MultiInputField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MultiInputField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'MultiInputField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'multiInputValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'subtext' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TextAreaField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TextAreaField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'TextAreaField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'textAreaValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'subtext' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'maxLength' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SelectChoiceField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'SelectChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SelectChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'choiceValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'subtext' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'options' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'label' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MultiSelectChoiceField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'MultiSelectChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'MultiSelectChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'multiChoiceValues' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'GridChoiceField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GridChoiceField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'GridChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'choiceValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'immutable' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'options' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'label' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'image' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'subtext' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'RadioChoiceField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RadioChoiceField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'RadioChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'immutable' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'choiceValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'view' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'options' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'label' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'subtext' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'BudgetField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'BudgetField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MultiChoiceField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MultiChoiceField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'MultiChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'multiChoiceValue' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'options' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'label' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'DateField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DateField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'DateField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'subtext' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'dateValue' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FilesField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FilesField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'FilesField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'subtext' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'allowedFileTypes' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'descriptiveText' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'filesValue' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bucket' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'previewable' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'size' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'downloadUrl' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'previewUrl' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SquarespacePackageChoiceField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'SquarespacePackageChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SquarespacePackageChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'LogoContestTierChoiceField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'LogoContestTierChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'LogoContestTierChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ContestUpgradesChoiceField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'ContestUpgradesChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ContestUpgradesChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PrintServiceOptionsField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'PrintServiceOptionsField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PrintServiceOptionsField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MultiGridChoiceField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'MultiGridChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'MultiGridChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'multiGridChoiceValue' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'subtext' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'maxSelections' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'validationPrompt' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'options' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'label' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'subtext' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'image' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'reviewImage' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ClientConfiguredField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'ClientConfiguredField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ClientConfiguredField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'questionId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'clientConfiguredValue' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'immutable' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'WorkEntityField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WorkEntityField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'WorkEntityField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'workEntityValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'immutable' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'BriefProduct' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'ProductFixedPriceProject' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'details' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'title' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pricing' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'earningsInUsd' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'amount' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'amountInCents' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'currency' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'fulfilmentStrategy' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fulfilmentMpvId' } },
                ],
            },
        },
    ],
};
export const GetCollaborationDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getCollaboration' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'collaborationPublicId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'getCollaboration' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'collaborationPublicId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'collaborationPublicId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'collaboration' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'deliveryVersions' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'versionId',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'state' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'mpvId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'culture' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'permissions' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'canRequestRevision',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'viewerRole' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'hasDesignLiveDelivery',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'isTest' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'printProduct' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'mpvId' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'productOptionsJson',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'revisions' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'isUnlimited',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'maximum',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'remaining',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'isFreelance' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'brief' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'Brief' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'Brief' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StructuredBrief' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StructuredBrief' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'BriefProduct' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'StructuredBrief' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StructuredBrief' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groups' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dynamicFields' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Field' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fulfilment' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'mpvId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'productKey' } },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'workName' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fulfilmentStrategy' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'Field' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DynamicField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InputField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MultiInputField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TextAreaField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SelectChoiceField' } },
                    {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MultiSelectChoiceField' },
                    },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GridChoiceField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RadioChoiceField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BudgetField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MultiChoiceField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DateField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FilesField' } },
                    {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SquarespacePackageChoiceField' },
                    },
                    {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'LogoContestTierChoiceField' },
                    },
                    {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ContestUpgradesChoiceField' },
                    },
                    {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PrintServiceOptionsField' },
                    },
                    {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MultiGridChoiceField' },
                    },
                    {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ClientConfiguredField' },
                    },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'WorkEntityField' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'InputField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InputField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'InputField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'helperText' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'inputValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'immutable' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'maxLength' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'descriptiveText' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MultiInputField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MultiInputField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'MultiInputField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'multiInputValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'subtext' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TextAreaField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TextAreaField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'TextAreaField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'textAreaValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'subtext' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'maxLength' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SelectChoiceField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'SelectChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SelectChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'choiceValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'subtext' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'options' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'label' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MultiSelectChoiceField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'MultiSelectChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'MultiSelectChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'multiChoiceValues' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'GridChoiceField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GridChoiceField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'GridChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'choiceValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'immutable' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'options' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'label' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'image' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'subtext' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'RadioChoiceField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RadioChoiceField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'RadioChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'immutable' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'choiceValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'view' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'options' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'label' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'subtext' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'BudgetField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'BudgetField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MultiChoiceField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MultiChoiceField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'MultiChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'multiChoiceValue' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'options' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'label' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'DateField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DateField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'DateField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'subtext' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'dateValue' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FilesField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FilesField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'FilesField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'subtext' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'allowedFileTypes' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'descriptiveText' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'filesValue' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bucket' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'previewable' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'size' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'downloadUrl' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'previewUrl' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SquarespacePackageChoiceField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'SquarespacePackageChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SquarespacePackageChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'LogoContestTierChoiceField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'LogoContestTierChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'LogoContestTierChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ContestUpgradesChoiceField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'ContestUpgradesChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ContestUpgradesChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PrintServiceOptionsField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'PrintServiceOptionsField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PrintServiceOptionsField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MultiGridChoiceField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'MultiGridChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'MultiGridChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'multiGridChoiceValue' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'subtext' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'maxSelections' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'validationPrompt' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'options' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'label' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'subtext' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'image' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'reviewImage' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ClientConfiguredField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'ClientConfiguredField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ClientConfiguredField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'questionId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'clientConfiguredValue' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'immutable' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'WorkEntityField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WorkEntityField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'WorkEntityField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'workEntityValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'immutable' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'BriefProduct' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'ProductFixedPriceProject' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'details' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'title' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pricing' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'earningsInUsd' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'amount' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'amountInCents' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'currency' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'fulfilmentStrategy' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'fulfilmentMpvId' } },
                ],
            },
        },
    ],
};

/**
 * __useGetCollaborationQuery__
 *
 * To run a query within a React component, call `useGetCollaborationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollaborationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollaborationQuery({
 *   variables: {
 *      collaborationPublicId: // value for 'collaborationPublicId'
 *   },
 * });
 */
export function useGetCollaborationQuery(
    baseOptions: Apollo.QueryHookOptions<GetCollaborationQuery, GetCollaborationQueryVariables>
) {
    return Apollo.useQuery<GetCollaborationQuery, GetCollaborationQueryVariables>(
        GetCollaborationDocument,
        baseOptions
    );
}
export function useGetCollaborationLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetCollaborationQuery, GetCollaborationQueryVariables>
) {
    return Apollo.useLazyQuery<GetCollaborationQuery, GetCollaborationQueryVariables>(
        GetCollaborationDocument,
        baseOptions
    );
}
export type GetCollaborationQueryHookResult = ReturnType<typeof useGetCollaborationQuery>;
export type GetCollaborationLazyQueryHookResult = ReturnType<typeof useGetCollaborationLazyQuery>;
export type GetCollaborationQueryResult = Apollo.QueryResult<
    GetCollaborationQuery,
    GetCollaborationQueryVariables
>;
