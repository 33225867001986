import { useMemo } from 'react';
import { getCurrentCulture } from '@99designs/design-services-common';
import { Event as ChatEvent } from '../ui';
import {
    ActivityPanelEventFragment,
    useGetEventsQuery,
} from '../views/ActivityPanel/Activity.generated';
import { ChatEvents } from '../views/ActivityPanel/ActivityPanel';

export const useActivityPanelData = ({
    collaborationPublicId,
    maxRevisionRequests,
    isDesignLive,
    hasUnlimitedRevisions,
}: {
    collaborationPublicId: string;
    maxRevisionRequests: number;
    isDesignLive: boolean;
    hasUnlimitedRevisions: boolean;
}) => {
    const currentCulture = useMemo(() => getCurrentCulture(), []);
    const { data, loading, error } = useGetEventsQuery({
        variables: { collaborationPublicId: collaborationPublicId },
    });

    if (data?.getCollaborationEventFeed?.events) {
        const events = data.getCollaborationEventFeed.events.map((e) =>
            mapFragmentToEvent(
                e,
                maxRevisionRequests,
                collaborationPublicId,
                isDesignLive,
                hasUnlimitedRevisions
            )
        );
        const datemap = events.reduce((acc, event) => {
            const date = new Date(event.createdAt).toLocaleTimeString(currentCulture, {
                weekday: 'short',
                month: 'short',
                day: 'numeric',
                hour12: true,
                hour: 'numeric',
                minute: 'numeric',
            });

            return {
                ...acc,
                [date]: [...(acc[date] || []), event],
            };
        }, {} as Record<string, ChatEvent[]>);

        const chatEvents: ChatEvents = Object.entries(datemap).map(([date, event]) => ({
            date: date,
            events: sortEventsByDate(event),
        }));

        return {
            chatEvents,
            loading,
            error,
        };
    }

    return {
        chatEvents: [],
        loading,
        error,
    };
};

function sortEventsByDate(events: ChatEvent[]): ChatEvent[] {
    return events.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateA.getTime() - dateB.getTime();
    });
}

export function mapFragmentToEvent(
    event: ActivityPanelEventFragment,
    maxRevisionRequests: number,
    collaborationPublicId: string,
    isDesignLive: boolean,
    hasUnlimitedRevisions: boolean
): ChatEvent {
    switch (event.type) {
        case 'EVENT_TYPE_CREATE_DELIVERY':
            if (event.content?.__typename === 'CreateDeliveryContent') {
                return {
                    type: 'revisionDelivery',
                    createdAt: event.createdAt,
                    data: {
                        deliveryVersionId: event.content.versionId,
                        collaborationPublicId,
                        message: event.content.message,
                        maxRevisionRequests,
                        hasUnlimitedRevisions,
                    },
                };
            }
            break;
        case 'EVENT_TYPE_REQUEST_REVISION':
            if (event.content?.__typename === 'RequestRevisionContent') {
                return {
                    type: 'revisionRequest',
                    createdAt: event.createdAt,
                    data: {
                        collaborationPublicId,
                        deliveryVersionId: event.content.versionId,
                        maxRevisionRequests,
                        message: event.content.message,
                        hasUnlimitedRevisions,
                    },
                };
            }
            break;
        case 'EVENT_TYPE_START_COLLABORATION':
            if (event.content?.__typename === 'StartCollaborationContent') {
                return {
                    type: 'startProject',
                    createdAt: event.createdAt,
                    data: {
                        isDesignLive: isDesignLive,
                    },
                };
            }
            break;
        case 'EVENT_TYPE_CANCEL_COLLABORATION':
            return {
                type: 'cancelCollaboration',
                createdAt: event.createdAt,
            };
        case 'EVENT_TYPE_APPOINTMENT':
            if (event.content?.__typename === 'AppointmentContent') {
                return {
                    type: 'appointment',
                    createdAt: event.createdAt,
                    data: {
                        type: event.content.type,
                        zoomMeetingUrl: event.content.zoomMeetingUrl,
                        dateTime: event.content.startTime,
                        designerName: event.content.designerName,
                        collaborationPublicId,
                        canReschedule: event.content.canReschedule,
                        deliveryVersionId: event.content.deliveryVersionId,
                    },
                };
            }
            break;
    }
    return {
        type: 'unknown',
        createdAt: event.createdAt,
        data: {},
    };
}
