import { useTracking } from '@vp/react-tracking';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IconProps } from '@vp/swan';
import { __ } from '@99designs/i18n';
import { COLLABORATE_BASE_PATH } from '../';
import { useCollaborationContext } from '../context';

export type NavigationLink = {
    icon: IconProps['iconType'];
    label: string;
    path?: string;
    onClick?: () => void;
    isActive?: boolean;
    openInNewTab?: boolean;
};

export function useNavigationLinks(): NavigationLink[] {
    const { trackingProperties, collaborationId } = useCollaborationContext();
    const { tracking } = useTracking();
    const navigate = useNavigate();
    const location = useLocation();
    const { deliveryVersionId } = useParams<{
        deliveryVersionId: string;
    }>();
    const deliveryVersionIdForRouting = deliveryVersionId || '1';

    return [
        {
            icon: 'image',
            label: __('Brief'),
            onClick: () => {
                const briefUrl = `${COLLABORATE_BASE_PATH}/${collaborationId}/delivery/${deliveryVersionIdForRouting}/brief`;

                tracking.track('Navigation Clicked', {
                    ...trackingProperties,
                    category: 'Collaboration Panel',
                    label: 'Sidebar:Collaboration Panel',
                    eventDetail: `/design/collaboration/${collaborationId};/delivery/${deliveryVersionIdForRouting}/brief;Collaboration Sidebar;Brief`,
                    navigationDetail: 'Brief',
                });

                navigate(briefUrl);
            },
            isActive: location.pathname.includes('/brief'),
        },
        {
            icon: 'liveChat',
            label: __('Activity'),
            onClick: () => {
                const activityUrl = `${COLLABORATE_BASE_PATH}/${collaborationId}/delivery/${deliveryVersionIdForRouting}/activity`;

                tracking.track('Navigation Clicked', {
                    ...trackingProperties,
                    category: 'Collaboration Panel',
                    label: 'Sidebar:Collaboration Panel',
                    eventDetail: `/design/collaboration/${collaborationId};/delivery/${deliveryVersionIdForRouting}/activity;Collaboration Sidebar;Activity`,
                    navigationDetail: 'Activity',
                });

                navigate(activityUrl);
            },
            isActive: location.pathname.includes('/activity'),
        },
    ];
}
