import React from 'react';
import { useScreenClass } from '@vp/swan';
import styled from 'styled-components';
import { BottomBar, Sidebar, Topbar } from '@99designs/design-services-layouts';

const ShellOuter = styled.div`
    display: flex;
    height: 100dvh;
    width: 100vw;
`;

const ShellInner = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 0;
    flex: 1;
`;

const Content = styled.div`
    flex: 1;
    overflow: auto;
`;

interface ShellProps {
    children?: React.ReactNode;
    topbarProps?: React.ComponentProps<typeof Topbar>;
    sidebarProps?: React.ComponentProps<typeof Sidebar>;
    bottomBarProps?: React.ComponentProps<typeof BottomBar>;
}

export const Shell: React.FC<ShellProps> = ({
    children,
    topbarProps,
    sidebarProps,
    bottomBarProps,
}) => {
    const screenClass = useScreenClass();

    return (
        <ShellOuter>
            {screenClass !== 'xs' && sidebarProps && <Sidebar {...sidebarProps} />}
            <ShellInner>
                {topbarProps && <Topbar {...topbarProps} />}
                <Content>{children}</Content>
            </ShellInner>
            {screenClass === 'xs' && bottomBarProps && (
                <BottomBar
                    {...bottomBarProps}
                    zIndex={3} // BottomBar zIndex needs to be higher than DrawerHome
                />
            )}
        </ShellOuter>
    );
};
