import { useTracking } from '@vp/react-tracking';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate } from 'react-router-dom';
import {
    ActivityPanel,
    COLLABORATE_BASE_PATH,
    EmptyCanvas,
    ErrorLoadingState,
    ResponsiveDrawerLayout,
    TRACKING_PAGE_MY_ACCOUNT,
    useActionContext,
    useActivityPanelData,
    useCollaborationContext,
} from '@99designs/collaborate-views';
import { __ } from '@99designs/i18n';

export const GetStarted = () => {
    const { collaborationId, deliveries, latestVersion } = useCollaborationContext();

    if (deliveries.length > 0) {
        return (
            <Navigate
                to={`${COLLABORATE_BASE_PATH}/${collaborationId}/delivery/${latestVersion}/activity`}
            />
        );
    }

    return (
        <>
            <Helmet>
                <title>{__('Vistaprint | Design Services Project Activity')}</title>
            </Helmet>
            <GetStartedDualPanelView />
        </>
    );
};

export const GetStartedDualPanelView = () => {
    const { tracking } = useTracking();
    const { collaborationId, revisionRequests, trackingProperties, hasDesignLiveDelivery } =
        useCollaborationContext();
    const { chatEvents, loading, error } = useActivityPanelData({
        collaborationPublicId: collaborationId,
        maxRevisionRequests: revisionRequests.max,
        isDesignLive: hasDesignLiveDelivery,
        hasUnlimitedRevisions: revisionRequests.isUnlimited,
    });
    const { actionOptions } = useActionContext();

    useEffect(() => {
        tracking.page('Design Services:Collaboration:Activity', {
            ...trackingProperties,
            pageName: `Design Services:Collaboration:Activity:${trackingProperties.collaborationStatus}`,
            pageSection: TRACKING_PAGE_MY_ACCOUNT,
            pageStage: TRACKING_PAGE_MY_ACCOUNT,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ResponsiveDrawerLayout
            left={
                <ErrorLoadingState
                    loading={loading}
                    loadingAccessibleText={__('Loading activity history')}
                    errors={error}
                    data={chatEvents}
                >
                    {(chatEvents) => (
                        <ActivityPanel
                            events={chatEvents}
                            requestRevision={actionOptions.requestRevision}
                            approve={actionOptions.approve}
                        />
                    )}
                </ErrorLoadingState>
            }
            right={<EmptyCanvas isDesignLive={hasDesignLiveDelivery} />}
            scrollPositionAtBottom
        />
    );
};
