import qs from 'qs';
import { SITE_FLOW_BASE_URL, requestor } from './constants';

export interface ProductOptions {
    [productOption: string]: string;
}

export const getNextPageUrl = async (
    culture: string,
    mpvId: string,
    productOptions?: ProductOptions
) => {
    const queryParams = {
        ...(productOptions && { optionValues: productOptions }),
        requestor,
    };

    const sfServiceUrl = `${SITE_FLOW_BASE_URL}/nextstep/vistaprint/${culture}/designservicesorderreview/${mpvId}?${qs.stringify(
        queryParams
    )}`;

    const response = await fetch(sfServiceUrl, {
        method: 'GET',
    });

    if (response.ok) {
        const nextPage = await response.json();
        return nextPage.url;
    } else {
        throw new Error(`Error getting PDC URL for design request ${JSON.stringify(response)}`);
    }
};
