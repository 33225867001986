import { useState } from 'react';
import {
    Box,
    Card,
    Divider,
    FlexBox,
    FluidImage,
    Li,
    Spinner,
    Typography,
    Ul,
    useScreenClass,
} from '@vp/swan';
import { Currency, Price, bugtracker } from '@99designs/design-services-common';
import { __ } from '@99designs/i18n';
import { BriefProductFragment } from '../BriefForm/brief.generated';
import { ProductModal } from '../ProductModal';
import { useProductImageUrlQuery } from './productImageUrl.generated';

export interface Product {
    title: BriefProductFragment['title'];
    details: Array<{
        __typename: 'ProductPackageDetails';
        title: string;
        description?: string;
    }>;
    additionalDetails?: Array<{
        __typename: 'ProductPackageDetails';
        title: string;
        description: string;
    }>;
    pricing: Omit<BriefProductFragment['pricing'], 'earningsInUsd'> & { earningsInUsd?: number };
    fulfilmentMpvId?: BriefProductFragment['fulfilmentMpvId'];
    description?: string;
}

export interface ProductCardProps {
    product: Product;
    withProductModal?: boolean;
    hideImage?: boolean;
    withDesignerEarnings?: boolean;
    fullWidth?: boolean;
}

export function ProductCard({
    product,
    withProductModal,
    hideImage,
    withDesignerEarnings,
    fullWidth,
}: ProductCardProps) {
    const screenClass = useScreenClass();
    const isMobile = screenClass === 'xs';
    const [isModalOpen, setIsModalOpen] = useState(false);

    const DesignerEarningsBlock = () => (
        <FlexBox marginTop="5" flexDirection="column">
            <Divider />
            <FlexBox gap="5" justifyContent="flex-end" style={{ width: '100%' }}>
                <Box textAlign="right">
                    <ProductPrice
                        amountInCents={product.pricing.amount.amountInCents}
                        currency={product.pricing.amount.currency}
                    />
                    <Typography fontSkin="body-small-bold" textColor="subtle">
                        {__('Customer cost')}
                    </Typography>
                </Box>
                {product.pricing.earningsInUsd && (
                    <Box textAlign="right">
                        <ProductPrice
                            amountInCents={product.pricing.earningsInUsd}
                            currency="USD"
                        />
                        <Typography fontSkin="body-small-bold" textColor="subtle">
                            {__('Your earnings')}
                        </Typography>
                    </Box>
                )}
            </FlexBox>
        </FlexBox>
    );

    return (
        <Card bordered p={'7'} mb="between-sections" style={{ minHeight: '224px' }}>
            <FlexBox
                gap={'7'}
                alignItems="center"
                justifyContent="space-between"
                flexWrap="wrap-reverse"
            >
                <Box style={{ width: isMobile || fullWidth ? '100%' : '70%' }}>
                    <Typography fontSize="large" fontWeight="bold">
                        {product.title}
                    </Typography>
                    <Ul mt={'4'}>
                        {product.details.map((item) => (
                            <Li pb={0} key={item.title}>
                                {item.title}
                            </Li>
                        ))}
                    </Ul>
                    {withProductModal && (
                        <ProductModal
                            isOpen={isModalOpen}
                            setIsOpen={setIsModalOpen}
                            product={product}
                        />
                    )}

                    {withDesignerEarnings ? (
                        <DesignerEarningsBlock />
                    ) : (
                        <ProductPrice
                            amountInCents={product.pricing.amount.amountInCents}
                            currency={product.pricing.amount.currency}
                        />
                    )}
                </Box>
                {product.fulfilmentMpvId && !hideImage && (
                    <ProductImage fulfilmentMpvId={product.fulfilmentMpvId} title={product.title} />
                )}
            </FlexBox>
        </Card>
    );
}

export function ProductPrice({
    amountInCents,
    currency,
    showFixedPrice,
}: {
    amountInCents: number;
    currency: string;
    showFixedPrice?: boolean;
}) {
    return (
        <Typography fontSize="large" fontWeight="bold" mt={'5'} mb={0}>
            <Price amount={amountInCents} currency={currency as Currency} />
            {showFixedPrice && (
                <Typography fontSize="xsmall" fontWeight="normal" ml="2" as="span">
                    {__('Fixed price')}
                </Typography>
            )}
        </Typography>
    );
}

function ProductImage({ fulfilmentMpvId, title }: { fulfilmentMpvId: string; title: string }) {
    const { data, loading, error } = useProductImageUrlQuery({
        variables: {
            mpvID: fulfilmentMpvId,
        },
    });

    if (error) {
        bugtracker.notify(new Error(`Error fetching product image URL: ${error.message}.`));
        return null;
    }

    if (!data || loading) {
        return <Spinner marginRight={'7'} size="super" accessibleText="Loading..." />;
    }

    return (
        <FluidImage
            style={{ maxHeight: '160px', maxWidth: '160px' }}
            src={data.productImageUrl}
            alt={title}
        />
    );
}
